<template>
  <pages-index-slug-component
    v-if="data"
    :data="data"
  />
  <shared-page-not-found-component v-else-if="!data" />
  <shared-error-component v-else-if="error" :error="error" />
</template>

<script lang="ts" setup>
const { data, error } = await useAsyncData(() => $fetch('/api/strapi-rest', { query: { path: queryPageEntryBySlugMain(getPageQuery()) } }) )

data.value = getFirstObject(data.value?.data)

setHead(data.value, getRandomImageFromBlocks(data.value?.blocks))
</script>
